<template>
  <main class="person-page page">
    <BreadcrumbsComponent v-if="person && person.title" :title="person.title" :links="links" />
    <div class="container-padding">
      <div v-if="person" class="person-page__inner">
        <ImgComponent :img="person.img" class="person-page__image" />
        <div class="person-page__content">
          <div class="person-page__info">
            <h2 v-if="person.title" class="person-page__title">{{ person.title }}</h2>
            <span v-if="person['post'] && person['post'].title" class="person-page__subtitle">
              <b>Должность: </b> {{ person["post"].title }}
            </span>
            <span v-if="person.education" class="person-page__subtitle">
              <b>Образование: </b> {{ person.education }}
            </span>
            <span v-if="person.data" class="person-page__subtitle">
              <b>Дата рождения: </b> {{ person.data | humanDate }} г.
            </span>
          </div>
          <div class="person-page__about">
            <span v-if="person.description">{{ person.description }}</span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import PERSONS_OPEN_PAGE from "gql/pages/PersonsOpenPage.graphql";

export default {
  name: "PersonsOpenPage",
  metaInfo() {
    const meta = this.$store.state.persons.persons_item;
    return {
      title: meta?.title,
    };
  },
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: PERSONS_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("persons/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  computed: {
    links() {
      let person = this.$store.state.persons.persons_item;
      let links = [];
      if (person.art) {
        links.push({ title: "Художественно-руководящий состав", to: { name: "art" } });
      }
      if (person.administration) {
        links.push({ title: "Администрация", to: { name: "administration" } });
      }
      if (person.artist) {
        links.push({
          title: person.category.title,
          to: { name: "persons", query: { category: person.category.id } },
        });
      }
      return links;
    },
    person() {
      return this.$store.state.persons.persons_item;
    },
  },
  components: {
    ImgComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.person-page {

  &__inner {
    padding 50px 0 0 0
    display grid
    grid-template-columns 350fr 1020fr
    grid-gap 30px
    +below(1200px) {
      grid-template-columns 1fr
    }
  }

  &__content {
    display flex
    flex-direction column
    gap 30px
  }

  &__info {
    display flex
    flex-direction column
    grid-gap 15px
  }

  &__title {
    margin-bottom 15px
  }

  &__subtitle {
    font-size 1.125rem
    font-weight 500
  }

  &__image {
    max-width 350px
    max-height 350px
    border-radius 30px
  }
}
</style>
